import React from 'react';
import './nav.css';

const Nav = ({ activeSection }) => {
  return (
    <nav>
      <a href="/" className={activeSection === 'home' ? 'active' : ''}>
        Home
      </a>
      <a href="#about" className={activeSection === 'about' ? 'active' : ''}>
        About
      </a>
      <a href="#services" className={activeSection === 'services' ? 'active' : ''}>
        Services
      </a>
      <a href="#products" className={activeSection === 'products' ? 'active' : ''}>
        Products
      </a>
      <a href="#contact" className={activeSection === 'contact' ? 'active' : ''}>
        Contact
      </a>
    </nav>
  );
};

export default Nav;