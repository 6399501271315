import React from 'react'
import './header.css'
import LOGO from '../../assets/logo.png'

const Header = () => {
  return (
    <header>
      <div className="container header__container">

        <div className="logo">
          <img src={LOGO} alt="sun" />
        </div>

        <div>
          <h1>NOBLETON</h1>
          <h2>POOLS AND SPAS</h2>
          <div className='cta'>
            <a href="#contact" className='btn'>Contact Us</a>
          </div>
        </div>

       </div>
    </header>
  )
}

export default Header