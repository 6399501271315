import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDd3dXoVnEI0d8fbe7DfoD7kdIPM0tZW6g",
  authDomain: "nobleton-pools-and-spas.firebaseapp.com",
  projectId: "nobleton-pools-and-spas",
  storageBucket: "nobleton-pools-and-spas.appspot.com",
  messagingSenderId: "232884100724",
  appId: "1:232884100724:web:97fc2de7fa697b67bdfb21",
  measurementId: "G-5M013834M6"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const db = getFirestore(app); // Initialize Firestore and add it here

export { db, app, analytics, auth, googleAuthProvider };