import React, { useState, useEffect } from 'react';
import './about.css';
import WATER from '../../assets/water.mp4';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const About = () => {
  const [aboutData, setAboutData] = useState({});
  const [hoursData, setHoursData] = useState({});
  const [locationData, setLocationData] = useState({});

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const aboutDocRef = doc(db, 'editableContent', 'about');
        const docSnap = await getDoc(aboutDocRef);

        if (docSnap.exists()) {
          setAboutData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    const fetchHoursData = async () => {
      try {
        const hoursDocRef = doc(db, 'editableContent', 'hours');
        const hoursDocSnap = await getDoc(hoursDocRef);

        if (hoursDocSnap.exists()) {
          setHoursData(hoursDocSnap.data());
        }
      } catch (error) {
        console.error('Error fetching hours data:', error);
      }
    };

    const fetchLocationData = async () => {
      try {
        const locationDocRef = doc(db, 'editableContent', 'location');
        const locationDocSnap = await getDoc(locationDocRef);

        if (locationDocSnap.exists()) {
          setLocationData(locationDocSnap.data());
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchAboutData();
    fetchHoursData();
    fetchLocationData();
  }, []);

  return (
    <section id="about">
      <h5>About</h5>
      <h2>Nobleton Pools and Spas</h2>

      <div className="container about__container">
        <div className="about__me">
          <video autoPlay loop muted className="about__me-video">
            <source src={WATER} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="about__content">
          <p>{aboutData['desc-1']}</p>
          <p>{aboutData['desc-2']}</p>
          <p>{aboutData['desc-3']}</p>
          <p>{aboutData['desc-4']}</p>
        </div>
      </div>

      <div className="about__card">
        <h5>Hours:</h5>
        <div className="hours">
          <div>
            <p>MONDAY</p>
            <p>TUESDAY</p>
            <p>WEDNESDAY</p>
            <p>THURSDAY</p>
            <p>FRIDAY</p>
            <p>SATURDAY</p>
            <p>SUNDAY</p>
          </div>
          <div>
            <p>{hoursData['monday']}</p>
            <p>{hoursData['tuesday']}</p>
            <p>{hoursData['wednesday']}</p>
            <p>{hoursData['thursday']}</p>
            <p>{hoursData['friday']}</p>
            <p>{hoursData['saturday']}</p>
            <p>{hoursData['sunday']}</p>
          </div>
        </div>
      </div>

      <div className="about__card">
        <h5>Location:</h5>
        <p>{locationData['address']}</p>
        <p>{locationData['city']}</p>
        <p>{locationData['postal-code']}</p>
      </div>

      <iframe
        className="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2874.459552035203!2d-79.65581162426547!3d43.908458935884724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2184c253fd5f%3A0x7a7c255cbe82285a!2sNobleton%20Pools%2C%20Spas%2C%20%26%20More!5e0!3m2!1sen!2sca!4v1692890265677!5m2!1sen!2sca"
        width="600"
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Nobleton Pools and Spas Location"
      ></iframe>
    </section>
  );
};

export default About;