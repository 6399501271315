import React, { useState } from 'react';
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {FiPhoneCall} from 'react-icons/fi'
import {useRef} from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  const [notificationVisible, setNotificationVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ex4o94k', 'template_lrrm89i', form.current, 'm9ERRKZcf33hZ4EIh')
      .then((result) => {
          console.log(result.text);
          setNotificationVisible(true);
          setTimeout(() => setNotificationVisible(false), 3000);
      }, (error) => {
          console.log(error.text);
      });
    
    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact Us</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>nobletonpools13305@gmail.com</h5>
            <a href="mailto:nobletonpools13305@gmail.com" target="_blank" rel="noreferrer">Send an Email</a>
          </article>

          <article className="contact__option">
            <FiPhoneCall className="contact__option-icon"/>
            <h4>Business Phone Number</h4>
            <h5>9058599200</h5>
            <a href="tel:9058599200" target="_blank" rel="noreferrer">Call Us</a>
          </article>

          <article className="contact__option">
            <FiPhoneCall className="contact__option-icon"/>
            <h4>Customer Service (Cell Phone)</h4>
            <h5>4169395321</h5>
            <a href="tel:4169395321" target="_blank" rel="noreferrer">Call James</a>
          </article>

        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder="Your Full Name" required/>
          <input type="email" name="email" placeholder="Your Email" required/>
          <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
        </form>
        {notificationVisible && (
          <div className="notification">Message sent successfully!</div>
        )}
      </div>
    </section>
  )
}

export default Contact