import React, { useState, useEffect } from 'react';
import './products.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const data = [
  {
    name: 'Pool Chemicals',
    field: 'pool-chemicals'
  },
  {
    name: 'Spa Chemicals',
    field: 'spa-chemicals'
  },
  {
    name: 'Various Pool & Plumbing Parts',
    field: 'parts'
  },
  {
    name: 'Equipment',
    field: 'equipment'
  },
  {
    name: 'Pool Accessories',
    field: 'accessories'
  },
];

const Products = () => {
  const [productsData, setProductsData] = useState({});

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const aboutDocRef = doc(db, 'editableContent', 'products');
        const docSnap = await getDoc(aboutDocRef);

        if (docSnap.exists()) {
          setProductsData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchProductsData();
  }, []);

  return (
    <section id="products">
      <h5>Overview of Our</h5>
      <h2>Products</h2>

      <Swiper
        className="container products__container"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {data.map(({ name, field }, index) => {
          return (
            <SwiperSlide key={index} className="product">
              <div className="product__content">
                <h3 className="product__name">{name}</h3>
                <p className="product__desc">{productsData[field]}</p>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Products;