import React, { useState, useEffect } from 'react';
import './editable.css';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const Editable = () => {
  const [editableData, setEditableData] = useState({
    about: {},
    hours: {},
    location: {},
    maintenance: {},
    'other-services': {},
    products: {},
  });

  useEffect(() => {
    const fetchData = async (contentName) => {
      try {
        const docRef = doc(db, 'editableContent', contentName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setEditableData((prevData) => ({
            ...prevData,
            [contentName]: docSnap.data(),
          }));
        } else {
          console.log(`No such document for ${contentName}!`);
        }
      } catch (error) {
        console.error(`Error fetching ${contentName} data:`, error);
      }
    };

    fetchData('about');
    fetchData('hours');
    fetchData('location');
    fetchData('maintenance');
    fetchData('other-services');
    fetchData('products');
  }, []);

  const handleInputChange = (contentName, event) => {
    const { name, value } = event.target;
    setEditableData((prevData) => ({
      ...prevData,
      [contentName]: {
        ...prevData[contentName],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (contentName, event) => {
    event.preventDefault();

    try {
      const docRef = doc(db, 'editableContent', contentName);
      await updateDoc(docRef, editableData[contentName]);

      console.log(`${contentName} data updated successfully!`);
    } catch (error) {
      console.error(`Error updating ${contentName} data:`, error);
    }
  };

  const renderEditableForm = (contentName, dataKey) => {
    return (
      <form onSubmit={(e) => handleSubmit(contentName, e)}>
        <div className="container editable-about__container">
          <div className="editable-about__content">
            <textarea
              name={dataKey}
              value={editableData[contentName][dataKey] || ''}
              onChange={(e) => handleInputChange(contentName, e)}
              className="editable-textarea"
            ></textarea>
          </div>
          <button type="submit" className="save-button">
            Save Changes
          </button>
        </div>
      </form>
    );
  };

  return (
    <section id="about">
      <h5>About:</h5>
      {renderEditableForm('about', 'desc-1')}
      {renderEditableForm('about', 'desc-2')}
      {renderEditableForm('about', 'desc-3')}
      {renderEditableForm('about', 'desc-4')}

      <h5>Monday Hours:</h5>
      {renderEditableForm('hours', 'monday')}
      <h5>Tuesday Hours:</h5>
      {renderEditableForm('hours', 'tuesday')}
      <h5>Wednesday Hours:</h5>
      {renderEditableForm('hours', 'wednesday')}
      <h5>Thursday Hours:</h5>
      {renderEditableForm('hours', 'thursday')}
      <h5>Friday Hours:</h5>
      {renderEditableForm('hours', 'friday')}
      <h5>Saturday Hours:</h5>
      {renderEditableForm('hours', 'saturday')}
      <h5>Sunday Hours:</h5>
      {renderEditableForm('hours', 'sunday')}

      <h5>Location:</h5>
      {renderEditableForm('location', 'address')}
      {renderEditableForm('location', 'city')}
      {renderEditableForm('location', 'postal-code')}

      <h5>Weekly or Biweekly Maintenance:</h5>
      {renderEditableForm('maintenance', 'weekly')}
      <h5>Chemical Check and Balance:</h5>
      {renderEditableForm('maintenance', 'chemical-check')}
      <h5>Salt Cell Cleaning:</h5>
      {renderEditableForm('maintenance', 'salt-cell')}

      <h5>Pool Opening:</h5>
      {renderEditableForm('other-services', 'pool-opening')}
      <h5>Pool Closing:</h5>
      {renderEditableForm('other-services', 'pool-closing')}
      <h5>Liner Installation:</h5>
      {renderEditableForm('other-services', 'liner')}
      <h5>Solar Blanket Installation:</h5>
      {renderEditableForm('other-services', 'solar-blanket')}
      <h5>Safety Cover Installation:</h5>
      {renderEditableForm('other-services', 'safety-cover')}
      <h5>Water Testing:</h5>
      {renderEditableForm('other-services', 'water-testing')}

      <h5>Pool Chemicals:</h5>
      {renderEditableForm('products', 'pool-chemicals')}
      <h5>Spa Chemicals:</h5>
      {renderEditableForm('products', 'spa-chemicals')}
      <h5>Pool & Plumbing Parts:</h5>
      {renderEditableForm('products', 'parts')}
      <h5>Equipment:</h5>
      {renderEditableForm('products', 'equipment')}
      <h5>Pool Accessories:</h5>
      {renderEditableForm('products', 'accessories')}
    </section>
  );
};

export default Editable;