import React, { useState, useEffect } from 'react';
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, googleAuthProvider } from '../../firebaseConfig';
import './adminlogin.css';

function AdminLogin() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [successfulNotifVisible, setSuccessfulNotifVisible] = useState(false);
  const [failedNotifVisible, setFailedNotifVisible] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const allowedEmails = ["nobletonpools13305@gmail.com"];
        const userEmail = user.email;

        if (allowedEmails.includes(userEmail)) {
          setIsAdmin(true);
          setSuccessfulNotifVisible(true);
          setTimeout(() => setSuccessfulNotifVisible(false), 3000);
        } else {
          setIsAdmin(false);
          signOut(auth);
          setFailedNotifVisible(true);
          setTimeout(() => setFailedNotifVisible(false), 3000);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="admin-login">
      {isAdmin ? (
        <button className="auth-button" onClick={handleLogout}>Logout</button>
      ) : (
        <button className="auth-button" onClick={handleLogin}>Admin Login</button>
      )}
      {successfulNotifVisible && (
        <div className="notification">Successfully logged in!</div>
      )}

      {failedNotifVisible && (
        <div className="notification">Not authorized to log in.</div>
      )}
    </div>
  );
}

export default AdminLogin;