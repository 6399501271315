import React, { useState, useEffect } from 'react';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Editable from './components/Editable';
import Services from './components/services/Services';
import Products from './components/products/Products';
import Contact from './components/contact/Contact';
import AdminLogin from './components/admin login/AdminLogin';
import './index.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
  
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
    });
  
    return () => unsubscribe();
  }, []);

  const handleScroll = () => {
    const sections = ['home', 'about', 'services', 'products', 'contact'];
    const scrollPosition = window.scrollY + 100;
  
    for (const section of sections) {
      const element = section === 'home' ? document.documentElement : document.getElementById(section);
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
          setActiveSection(section);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <AdminLogin />
      <Header />
      {!user && <Nav activeSection={activeSection} />}
      {user && <Editable />}
      {!user && <About />}
      {!user && <Services />}
      {!user && <Products />}
      {!user && <Contact />}
    </>
  );
};

export default App;