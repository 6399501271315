import React, { useState, useEffect } from 'react';
import './services.css'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import BEFORE from '../../assets/before_balance.jpg'
import AFTER from '../../assets/after_balance.jpg'
import CLEANING from '../../assets/pool_cleaning.jpg'
import SAFETYCOVER from '../../assets/safety_cover.jpg'
import TEST from '../../assets/pool_test.png'
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';


const Services = () => {
  const [maintenanceData, setMaintenanceData] = useState({});
  const [otherData, setOtherData] = useState({});

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const aboutDocRef = doc(db, 'editableContent', 'maintenance');
        const docSnap = await getDoc(aboutDocRef);

        if (docSnap.exists()) {
          setMaintenanceData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching maintenance data:', error);
      }
    };

    const fetchOtherData = async () => {
      try {
        const hoursDocRef = doc(db, 'editableContent', 'other-services');
        const hoursDocSnap = await getDoc(hoursDocRef);

        if (hoursDocSnap.exists()) {
          setOtherData(hoursDocSnap.data());
        }
      } catch (error) {
        console.error('Error fetching other services data:', error);
      }
    };

    fetchMaintenanceData();
    fetchOtherData();
  }, []);

  return (
    <section id="services">
      <h2>Services</h2>

      <div className="services">
        <div className="container services__container">
          <article className="service">
            <div className="service__head">
              <h3>Maintenance</h3>
            </div>
            <div className="service__list">

              <div className="two-columns">
                <div className="column column-1">
                  <img src={CLEANING} className="image" alt="Cleaning"></img>
                </div>
                <p className="column column-2">
                <br></br><strong>Weekly or Biweekly Maintenance:</strong><br></br>
                {maintenanceData['weekly']}<br></br><br></br>
                </p>
              </div>
              <p><br></br><em>Shown Below:</em> <strong>Before and After</strong> Maintenance for One of our Customers!</p>
              <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src={AFTER} className="image"/>}
              itemTwo={<ReactCompareSliderImage src={BEFORE} className="image"/>}
              />
              <p>
                <br></br><strong>Chemical Check and Balance:</strong><br></br>
                {maintenanceData['chemical-check']}<br></br><br></br>
                <strong>Salt Cell Cleaning:</strong><br></br>
                {maintenanceData['salt-cell']}
              </p>
            </div>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Pool Opening</h3>
            </div>

            <div className="service__list">
            {otherData['pool-opening']}
            </div>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Pool Closing (Winterization)</h3>
            </div>
            <div className="service__list">
            {otherData['pool-closing']}
            </div>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Liner Installation</h3>
            </div>
            <div className="service__list">
            {otherData['liner']}
            </div>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Solar Blanket Installation</h3>
            </div>
            <div className="service__list">
            {otherData['solar-blanket']}
            </div>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Safety Cover Installation</h3>
            </div>
            <div className="service__list">
            <p>{otherData['safety-cover']}<br></br><br></br></p>
            <img className="image" src={SAFETYCOVER} alt="Safety Cover"></img>
            </div>
          </article>

          <article className="service">
            <div className="service__head">
              <h3>Water Testing (In-Store)</h3>
            </div>
            <div className="service__list">
              <p>
              {otherData['water-testing']}<br></br><br></br>
              <em>Shown Below:</em> Example of Water Test Results
              </p>
              <img className="image" alt="Water Testing" src={TEST}></img>
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Services